@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600');
@font-face {
    font-family: 'icons';
    src: url('icomoon.ttf') format('truetype')
}

@font-face {
    font-family: 'qoagricola';
    src: url('qoagricola.otf') format('truetype')
}

*{margin:0;box-sizing: border-box}
body{font-family: 'Poppins', sans-serif}

.pc_d_none{display: none !important;}
#root{margin-top: 72px}
header{z-index: 9000;padding: 0 20px;position: fixed;background: #fff;left: 0;right: 0;top: 0}
form input::placeholder{color: #000;font-family: 'Poppins', sans-serif;}
.flex{display: flex;justify-content: space-between}
.c-0{margin: 0 auto;width:1100px}
.c{margin: 0 auto;width:1100px;position: relative;z-index: 1;}
.cc{margin: 0 auto;width:900px}

.single h1{font-size: 1.4em;}

.logo{height: 55px; margin: 5px 0}

h2{font-size: 1.3em}

header form{padding: 5px}
header form input{padding: 0;border: none;background-color: transparent}
header form input[type=submit]{margin-right: 0!important;font-size: 1.2em;cursor: pointer}

header ul{display: flex}
header ul li a{padding: 14px 20px;;display: inline-block;font-size: .9em;font-weight: 400;}
header ul ul{position: absolute;top: 100%;padding: 15px;display: none;min-width: 200px;}
header nav{margin-bottom: -1px;margin-right: 100px;}
header nav ul{padding:0;justify-content: center}
header nav ul ul li a{padding: 15px 20px}
header ul li:hover ul{display: block}

header li, footer li, .contact li {list-style: none; margin-left: 0}

header .__menu{padding-top: 15px}

.notif_badge{display: inline-block;position: relative;}
.notif_badge .badge{display: block;position: absolute;width: 12px;height: 12px;border-radius: 8px;right: -6px;bottom: 5px;}

.search_box{position: absolute;top: 72px;right: 0;z-index: 9999;width: 350px}
.search_box div{background: #fff;border-radius: 6px;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);padding: 5px;}
.search_box div a{display: block;color: #333;padding: 8px;border-radius: 6px;}
.search_box div a:hover{background-color: rgba(0, 0, 0, 0.05);}
.search_box span{font-size: 1.2em;margin: 0 8px;}

.topbar{padding: 5px;text-align: center;}
.topbar span{font-weight: lighter;text-transform: uppercase;font-size: .8em;letter-spacing: 4px;}

.trait{border-right: 2px solid #999;margin-right: 0px;margin-left: -20px;}

.caroussel{width: 100%;height: 550px;z-index: 6000;position: relative;overflow: hidden;}
.caroussel .slider{transition: 0.4s ease;display: flex; width: 100%;height: 550px;}
.caroussel .child{position:relative;display: flex;justify-content:center;flex-wrap: wrap;}
.caroussel .child img{position:absolute;width:100%;height:100%;object-fit: cover;}
.caroussel .child div{position:relative;color: #fff;}
.caroussel .masq .box{padding: 25px;border-radius: 5px;}
.caroussel .masq .box h1{font-weight: lighter;text-transform: uppercase;font-size: 1.35em;letter-spacing: 2.5px;line-height: 1.5em;}
.caroussel .masq .box h1 span{display: block;margin-bottom: 15px;}

.srch{position: absolute;bottom: 100px;right: 200px;left:0;}
.search .srch{position: initial;}
.search .srch form{border: 1px solid rgb(245, 244, 244);}
.srch input{padding: 5px 10px;border: none;width: 100%;font-size: 1.2em;}
.srch form input::placeholder{width: 90%;font-weight: 500;}
.srch form p{font-size: .8em;padding: 0 10px;}
.srch form .flex div{width: 90%;}

.brcText{-webkit-text-stroke: 5px #000;}

.masq{position: absolute;width: 100%;height: 600px;left: 0;top:50px;bottom:0;}

.btn{padding: 6px 12px;cursor: pointer;border-radius: 5px;font-size: .8em;display: inline-block;}
.btn .icon, .btn.icon{text-transform: none;}

.ovh{overflow: hidden;}

.c1, .c1 .c, .c2, .c2 .c, .c3, .categories{position: relative;}

.shape1{position: absolute;top: -70px;left: 0;z-index: 0;}
.shape2{position: absolute;top: -300px;right: 0;z-index: 0;}
.shape3{position: absolute;top: 0;left: 0;z-index: 0;}
.shape4{position: absolute;top: -280px;left: 0;z-index: 0;}

.categories .shape1{top:200px}

.c3{height: 116px}
.c3 .cnt{position: absolute;bottom: 0;left: 0;right: 0;text-align: center;}
.c3 .cnt div span{display: block;}
.c3 .cnt div span:first-child{margin-bottom: 5px;font-weight: 400;font-size: 1.1em;}
.c3 .cnt div span:last-child{font-size: .8em;}
.c3 .flex span.mr10{font-size: .8em !important;font-weight: 400 !important}
.c3 img{height: 50px}

.article{border-radius: 10px;padding: 10px;z-index: 800;}
.article .img{border-radius: 10px;overflow: hidden;height: 240px;}
.article .img img{width: 100%;height: 100%;object-fit: cover;}
.article .cnt{padding: 10px 0 0}
.article .cnt .flex{align-items: center;}
.article .cnt .price{font-size: 1.2em;font-weight: 600;}
.article .cnt .icon{font-size: 1.4em;}
.opt{display: inline-block;padding: 4px 0;text-align: center;;border-radius: 30px;width: 30px;height: 30px;color: #fff;margin-bottom: 10px;}
.opt.mini{width: 16px;height: 16px}
.article .cnt .opt.icon{padding-top: 2px;font-size: 1.1em;}
.article .cnt .t{display: block;text-wrap: nowrap;overflow: hidden;text-overflow: ellipsis;}
.articles.small .article .img{height: 180px;}

.cat_filter input{border-style: none;border-bottom-style: solid;border-bottom-width: 1px;background: transparent;padding: 4px;}

.service{border-radius: 10px;border-width: 3px;border-style: solid; padding: 10px 15px;}
.service .place{display: flex;justify-content: start; align-items: end;margin-bottom: 15px;}
.service .price{font-weight: 600;margin-bottom: 20px;}
.service ul{padding: 10px 0;border-bottom-width: 1px;border-bottom-style: solid;}
.service ul li{list-style: none;font-family: 'qoagricola', sans-serif; margin-bottom: 7px;}
.service ul li .icon{margin-right: 8px;}
.service .bottom{display: flex; align-items: center; justify-content: space-between;margin-top: 10px;}
.service .user .icon{margin-right: 5px;}
.service .bottom >span.icon{font-size: 1.6em;}

.plantsitting .gla-form.head_form{width: 80%;}
.plantsitting .gla-form input.search_input{border-radius: 5px;border: 1px solid rgba(0, 0, 0, 0.4);padding-left: 33px;width: 100%;}
.plantsitting .gla-form .icon{position: absolute;top: 6px;left: 10px;}

.listings{margin-bottom: 30px;}
.listings .top{display: flex; justify-content: space-between;align-items: center;margin-bottom: 20px;}
.user{display: flex; justify-content: start; align-items: center;}
.av{width: 35px;height: 35px;border-radius: 50%;overflow: hidden;background-color: #b5b5b8;margin-right: 10px;}
.av.medium{width: 50px;height: 50px;}
.av.large{width: 90px;height: 90px;}
.av img{width: 100%;height: 100%;object-fit: cover;}
.rating{margin-left: 20px;}
.listings .link{font-size: .9em;text-decoration: underline;}

.rating .icon{color: #FABD05;}

.divider{border-top: 1px solid #F3F4F6;text-align: center;}
.divider::after{content: "ou";background-color: #fff;margin-top: -17px;display: inline-block;padding: 5px 10px;color: #666;}

table{border-collapse: collapse;border-radius: 8px;overflow: auto}
table{margin:30px 0;color:#000;border-collapse: collapse; border:0}
table{border-spacing: 1;border-collapse: collapse; background:white;border-radius:6px;max-width:1200px; width:100%;margin:0 auto;border: 1px solid #40bd6e40;}
table tr{border-bottom:1px solid #40bd6e40!important}
table th{width:20%!important; padding: 15px;background-color: #40bd6e40!important;font-weight: 700;font-size: 1.1em;text-transform: uppercase; }
table td{padding: 15px;text-align: left;vertical-align:middle;font-weight: 300;font-size: .9em;}

.more-img img{width:100%;height: 330px;object-fit: cover;cursor: pointer; transition: 2s ease;}
.more-img img:hover{transform: scale(110%);}
.more_services a{display: block;margin-top: 10px; font-size: 1.2em;}

.single .c-infos .price{font-size: 1.7em;font-weight: 500;}
.single .images{position: relative;}
.single .images .img{height: 200px;}
.single .images .img img{object-fit: cover;width: 100%;height: 100%;border-radius: 10px;}
.single .images .img:first-child{grid-column: 1 / 3;grid-row: 1 / 2;}
.single .images .btn2{position: absolute; bottom: 20px;right: 20px; padding: 5px 10px;border-radius: 5px;font-size: .7em;cursor: pointer}
.single .images > div{max-height: 200px;overflow: hidden;}

.single .rating{margin-left: 0;}

.content{padding: 30px 0;}

.login, .content{min-height: calc(100vh - 127px)}

.tag{border-radius: 20px; padding: 10px 20px;display: inline-block;margin-right: 20px;margin-bottom: 20px;}
.tag .icon{margin-right: 8px;}

.comment{display: flex; padding: 15px;border-radius: 15px;margin-bottom: 20px;}
.comment .av{border-radius: 15px;width: 60px;height: 60px;background-color: #F3F4F6;overflow: hidden;margin-right: 10px;}
.comment .av img{width: 100%;height: 100%;object-fit: cover;}
.comment .n{font-weight: 500;}
.comment .d{font-size: .8em;}
.comment .rating{margin: 8px 0;}

.paragraph p{margin-bottom: 10px;}
.paragraph ul{margin-bottom: 20px;}
.paragraph li{margin-bottom: 10px;}

.aside .t{text-transform: uppercase;margin-bottom: 15px;margin-top: 30px;;display: block;font-weight: 500;}
.aside .t:first-child{margin-top: 0;}

._choix{cursor: pointer}

.aside p{font-family: 'qoagricola', sans-serif; display: block;margin-bottom: 10px;cursor: pointer;}

.slide {-webkit-appearance: none;width: 80%;height: 4px;border-radius: 5px;outline: none;}
.slide::-webkit-slider-thumb {-webkit-appearance: none;appearance: none;width: 20px;height: 20px;border-radius: 50%;background: #FF8A34;cursor: pointer;}
.slide::-moz-range-thumb {width: 20px;height: 20px;border-radius: 50%;background: #FF8A34;cursor: pointer;}

.check{content: "&#xe080"; }

.note, .trans{border-radius: 10px;padding: 20px;margin-bottom: 20px;}
.note .flex{margin-bottom: 20px;}
.note .date{font-size: .8em;font-weight: 300;}
.note p{font-weight: 300;}

.sittings  .service .bottom >span{font-size: .8em;}

.trans{display: flex;justify-content: space-between;align-items: center;}
.trans .av{margin-right: 20px;}
.trans .infos{display: flex;justify-content: start;align-items: center;width: 80%;}
.trans .infos span{margin-right: 20px;}
.trans .date{text-transform: uppercase;font-weight: 300;font-size: .9em;}

.settings .gla-form input:not(.btn), .settings .gla-form textarea, .settings .gla-form select{border: 1px solid rgba(118, 118, 118, 0.6);width: 65%;}
.settings .gla-form label{width: 65% !important}

.contact-form{box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;}


.facebook{background-color: #4267B2}
.twitter{background-color: #1DA1F2}
.instagram{background-color: #e95950}
.kiuper{background-color: #6F9C00}

.contact p{margin-bottom: 10px;}

.gla-pagination a,.gla-pagination .btn{padding: 8px 15px;margin-right: 5px}
.gla-pagination{text-align: right}

.more-articles{position: relative;}
.more-articles .article{margin-right: 10px;width: 100%;}
.more-articles h2{margin: 30px 0 15px 0}

.car-slide{overflow: hidden;margin: 40px 0;position: relative;}
.car-slide .slide{display: flex;justify-content: flex-start;transition: 0.1s linear;padding: 0 20px;}
.car-slide .article{margin-right: 20px}
.btn.sld{position: absolute;top: 50%;padding:12px 15px;text-align: center;}
.btn.sld#nx{right: 0;cursor: pointer}
.btn.sld#pr{transform: rotate(180deg)}

.page_page img{width: 100%;height: auto;}

.discussions_page{height: calc(100vh - 150px);margin-top: 20px;}

.discussions{overflow-y: auto;height: calc(100vh - 160px)}
.discussion{display: flex;justify-content: start;align-items: center;padding: 4px;border-radius: 8px;margin-bottom: 10px}
.discussions .discussion:hover, .discussions .discussion.active{background-color: #e2dfd7}
.discussion img{width: 60px;height: 60px;border-radius: 60px;margin-right: 10px;object-fit: cover;}
.discussion p{color: #333}
.discussion span, .discussion a{color: #999;font-size: 0.8em}
.discussion span.badge{color: #fff;background: #FF8A34; font-size: 0.7em;padding: 0 5px;border-radius: 20px}

.messages{height: calc(100vh - 310px);overflow-y: auto;}
.message{margin-bottom: 10px}

.message .user{font-size: 0.9em;}

.send_message{margin-bottom: 10px;}
.send_message input{margin: 0 !important}
.send_message input[type=text]{width: 100% !important}

.fltr_del{padding: 1px 6px;border-radius: 3px;}

form input, form textarea, form select,button{font-family: 'Poppins', sans-serif}
textarea:focus, input:focus{outline: none;}
textarea{height: 34px;border: 1px solid #464747 !important;resize: vertical;height: 90px;}
.gla-form input:not(.btn),.gla-form textarea,.gla-form select{border: none;width: 65%;padding: 7px;background-color: transparent;}
.gla-form select{border-bottom-width:1px; border-bottom-style: solid;}
.gla-form label, .gla-form div.label{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;}
.gla-form label span{font-weight: 500;}
.gla-form input[type=submit]{width: auto;margin-top: 15px; }
.gla-form input[type=file], .gla-form input[type=checkbox]{width: auto;}
.gla-form input::file-selector-button, .gla-form button{border-radius: 5px;padding: 5px 40px;border: 1px solid #464747;background-color: transparent;}
.gla-form input[type=checkbox], .gla-form input[type=radio]{width: 18px;}

.gla-form ._choix{cursor: pointer}
.gla-form ._choix input{opacity: 0}

.register .gla-form input{width: 100%; border-bottom-width: 1px ;border-bottom-style: solid;margin-bottom: 20px;}
.register .gla-form input[type=checkbox]{width: 35px; margin-top: 5px;}
.register .gla-form .flex div{width: calc(100% - 35px); }

.gla-cptch input{width: 60px !important;}

.btn_social_connect a{border-radius: 5px;padding: 10px 30px; text-align: center;color: #000; display: block;margin-bottom: 20px;}
.btn_social_connect .icon{margin-right: 8px; }

.mask, .mask2{position: fixed;top: 0;bottom: 0;left: 0;right: 0;background: rgba(0,0,0,0.4);display: flex;align-items: center;justify-content: center;padding:20px;z-index: 9999}
.mask img{max-width: 100%;max-height: 100%;background: rgba(255,255,255,0.5);border-radius:3px;transition: 0.14s linear}
.mask .exit, .mask .btn{position: absolute;text-align: center;line-height: 40px;font-size: 2.6em;cursor: pointer;padding: 0px 13px}
.mask .exit{right: 10px;top: 25px !important;border-radius: 5px;}
.mask .btn{top: 48%;transition: 0.14s linear;background: transparent;vertical-align: middle}
.mask .btn-next{right: 20px;}
.mask .btn-prev{left: 20px}
.mask .btn-next:hover,.mask .btn-prev:hover,.mask .exit:hover{opacity: 1}

.mask2 .popup .exit{position: absolute;top: -6px;right: -6px;font-size: 1.6em;}

.succes, .error{display:block;margin: 20px 0;padding:10px;border-radius: 8px;}
.succes{background: #6eab69;color: #fff;}
.error{background: rgba(255,0,0,0.3);color: rgba(255,0,0,0.9)}

.rem{font-size: 0.9em !important;color: #666;font-style: italic}
a{text-decoration: none;}
.flex-c-reverse{flex-direction: column-reverse}
.ml20per{margin-left: 20%;}
.cur_p{cursor: pointer;}
.zi-500{z-index: 500}
.l_mj{text-transform: uppercase;}
.t_und{text-decoration: underline;}

.vender_img > div{position: relative;}
.vender_img .icon{position: absolute;right: -5px;top: -7px;font-size: 2em;}

.input_eye{position: relative}
.input_eye svg{position: absolute;cursor: pointer;right: 10px;top: 4px;}

.boite-box{background-color: #879F85;border-radius: 30px;padding: 20px;position: relative;color: #fff;margin: 100px auto 80px;}
.boite-box img{border: 12px solid #879F85;border-radius: 130px;width: 130px;height: 130px;object-fit: cover;position: absolute;top: -65px;left: calc(50% - 65px)}
.boite-box a{display: block;color: #fff;text-decoration: underline;margin-bottom: 10px;font-size: 0.9em}

span.menu-res{display: none}

.icon{font-family: 'icons' !important;line-height: 1.5;vertical-align: middle}

footer .grid3 a{display: block;margin-top: 14px;}
footer .social{display: flex;flex-direction: column;align-items: end;}
footer .social .flex a{margin-top: 17px}

/* Loader ------------------------------- */

.lds-dual-ring {display: inline-block;width: 40px;height: 40px;margin: 0 auto;}
.lds-dual-ring:after {content: " ";display: block;width: 34px;height: 34px;margin: 8px;border-radius: 50%;border: 6px solid #333;border-color: #333 transparent #333 transparent;animation: lds-dual-ring 1.2s linear infinite;}
@keyframes lds-dual-ring {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}} 

div::-webkit-scrollbar {width: 5px}
div::-webkit-scrollbar-track {background: #eee}
div::-webkit-scrollbar-thumb {background: #ddd}

/* GLA Slider ---------- */

.gla_slider{position: relative;overflow: hidden;}
.gla_slider .gla_slider_slider{width:100%;display: flex;transition: 0.4s ease;flex-wrap: nowrap}

.gla_slider .slider_btn{position: absolute;color: #000;border: 3px solid #000;bottom:0;padding: 3px;border-radius: 40px;cursor: pointer;transition: 0.2s ease;font-size: 1.5em;}
.gla_slider .slider_btn:hover{opacity: 1;}
.gla_slider .next_btn{right: 15px; }
.gla_slider .next_btn:before{content: "\ea6e";}
.gla_slider .previos_btn{right: 60px}
.gla_slider .previos_btn:before{content: "\ea64";}

.caroussel .gla_slider .slider_btn{padding: 6px;}
.caroussel .gla_slider .next_btn{right: 10px; }
.caroussel .slider_btn{bottom: 10px;top: auto}
.caroussel .previos_btn{left: auto; right: 60px;}



/* GLA Alert ---------- */

.mask{display: flex;align-items: center;justify-content: center;}

.gla_alert{width: 400px;background: #fff;box-shadow: 0 0 20px rgba(0,0,0,0.3);}
.gla_alert p{padding: 20px;}
.gla_alert .gla_btns{padding: 10px;background: rgba(0,0,0,0.04);border-top: 1px solid rgba(0,0,0,0.1);display: flex;justify-content: flex-end;}
.gla_alert .gla_btns span{margin-right: 20px;font-weight: bold;text-transform: uppercase;cursor: pointer;}


/* GLA Captcha ---------- */

.gla_captcha{background: #eee;border:1px solid rgb(223, 222, 222);border-radius: 8px;padding: 10px;}
.gla_captcha h3{font-weight: normal;margin-bottom: 10px;}
.gla_captcha span{background: #fff;padding: 5px;color: #333;width: 30px;text-align: center;cursor: pointer;opacity: 0.7;border-radius: 8px;}
.gla_captcha span:hover{opacity: 1;}
.gla_captcha input{width: 100px;height: 30px;}

.pulse {
    animation: pulse 0.4s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from { transform: scale(0.95); }
    to { transform: scale(1.05); }
  }