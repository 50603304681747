/* Credit Card */

.credit-card {
    width            : 360px;
    margin           : 0px auto;
    border           : 1px solid #ddd;
    border-radius    : 6px;
    background-color : #fff;
    box-shadow       : 1px 2px 3px 0px rgba(0,0,0,0.10);
  }
  
  .form-header {
    height        : 60px;
    padding       : 20px 30px 0;
    border-bottom : 1px solid #E1E8EE;
  }
  
  .form-body {
    padding : 30px 30px 20px;
  }
  
  /* Title */
  
  .title {
    margin    : 0;
    color     : #5e6977;
    font-size : 18px;
  }
  
  /* Common */
  
  .card-number,
  .cvv-input input,
  .month select,
  .paypal-btn,
  .proceed-btn,
  .year select {
    height : 42px;
  }
  
  .card-number,
  .cvv-input input,
  .month select,
  .year select {
      font-size: 14px;
      font-weight: 100;
      line-height: 14px;
  }
  
  .card-number,
  .cvv-details,
  .cvv-input input,
  .month select,
  .year select {
    color   : #86939e;
    opacity : .7;
  }
  
  /* Card Number */
  
  .card-number {
    width         : 100%;
    margin-bottom : 20px;
    padding-left  : 20px;
    border        : 2px solid #e1e8ee;
    border-radius : 6px;
    color: #333;
    font-weight: 500;
  }
  
  /* Date Field */
  
  .month select,
  .year select {
    -moz-appearance     : none;
    -webkit-appearance  : none;
    width               : 145px;
    margin-bottom       : 20px;
    padding-left        : 20px;
    border              : 2px solid #e1e8ee;
    border-radius       : 6px;
    background-position : 85% 50%;
    color: #333;
    font-weight: 500;
  }
  
  .month select {
    float : left;
  }
  
  .year select {
    float : right;
  }
  
  /* Card Verification Field */
  
  .cvv-input input {
    width         : 145px;
    float         : left;
    padding-left  : 20px;
    border        : 2px solid #e1e8ee;
    border-radius : 6px;
    background    : #fff;
    color: #333;
    font-weight: 500;
  }
  
  .cvv-details {
    float         : right;
    margin-top : 8px;
    font-size     : 12px;
    font-weight   : 300;
  }
  
  .cvv-details p {
    margin-top : 6px;
  }
  
  /* Buttons Section */
  
  .paypal-btn,
  .proceed-btn {
    cursor: pointer;
    width         : 100%;
    border-color  : transparent;
    border-radius : 6px;
    font-size     : 16px;
  }
  
  .proceed-btn {
    margin-top : 20px;
  }
  
  .paypal-btn a,
  .proceed-btn a {
    text-decoration : none;
    cursor          : pointer;
  }
  
  .proceed-btn a {
    color : #fff;
  }