@media (max-width : 960px) {

    .c, .cc, .c-0, .head, .content .box{width: 100%;padding: 0}

    .content{display: block}

    span.menu-res{display: none}
    .cont,aside{width: 100%}

    .cc.content{padding: 20px;margin-top: 60px;width: 100%;overflow: hidden;}

    body{background-color: #fff !important}

    .pc_d_none{display: inline-block !important}

    .flex{display: block}
    .content .c{display: block;padding: 0 10px}

    header{width: 100%;padding:10px 10px 0}
    header > div{border-bottom: 1px solid #999;}
    header .logo{height: 40px;}

    header .flex-end{justify-content: flex-end}
    header .btn{margin-left: 0;margin-top: 10px;}

    .topbar{visibility: hidden;height: 18px;}

    span.menu-res{transition: 0.15s linear;display: inline-block;}
    .mn .menu-res{z-index: 9999;position: relative;background-color: #fff;border-radius: 5px;}
    .mn nav{right:0;}

    .menu_open_off, .mn .menu_open_on{display: none !important}
    .mn .menu_open_off{display: inline-block !important}

    .__menu{margin-right: 10px}

    header nav{position: fixed;overflow:hidden;overflow-y:auto;height:100%;right:-100%;top: 68px;width: 100% !important;background-color: #fff;transition: 0.15s linear;z-index: 9900;margin-right: 0;}
    header nav ul{display: block;padding: 0}
    header nav ul li{display: block;border-bottom: 1px solid #eee;padding: 10px;}
    header nav ul li:hover{background: transparent}
    header nav ul li a{padding: 10px;display:block;color:#454546}
    header nav ul li a span.icon2{display: none}
    header nav ul li ul{position: relative;margin-left: 20px;box-shadow: none;left: 0;display: block;padding: 0;background-color: transparent !important;}
    header nav ul li ul li{border-bottom: none}
    header nav li ul h3,header nav li ul .top{display: none}
    header nav li ul .mn,header nav li ul li{width: 100%;margin-right: 5px}
    header nav ul li ul li a{color:#6a6a70}


    .m_search_box{position: relative;top: 0;right: 0;width: 100%;z-index: 800}
    .m_search_box > div{background-color: #D9D9D9;padding: 2px;margin-bottom: 0 !important;box-shadow: none;}
    .m_search_box input{width: 88%;}

    .m_home_head{position: relative;background-color: #fff;text-align: center;padding: 20px 30px !important;margin-bottom: 0 !important;}
    .m_home_head h1{color: #333;margin-bottom: 30px;}
    .m_home_head p{color: #333;font-weight: 500;}
    .m_home_head img{position: relative;z-index: 1;}

    .caroussel{height: 560px;}

    .btn_vendre{width: 80%;margin: -20px auto 0;z-index: 8000;position: relative;display: block !important;}

    .c3{position: relative;height: auto;margin-top: 245px;}
    .c3 .cnt{background-color: #fff;}
    .c3 .cnt a{border: 1px solid var(--cl4);display: block;margin-top: 20px;border-radius: 5px;padding: 10px 0;color: var(--cl8);}

    .article .img, .articles.small .article .img{height: 180px;}

    .c1{padding-top: 40px;}

    .trans{display: block; padding: 20px;}
    .trans .infos{width: 100%;flex-wrap: wrap;}
    .trans .infos span:last-child{width: 100%;display: block;margin: 10px 0;}

    .grid4.articles{display:grid;grid-template-columns: repeat(2, calc( 50% - 5px ) [col-start]);padding: 10px;grid-gap: 10px;}

    .listings .top{display: block;padding: 10px;}

    .single .images .img:first-child{grid-column: 1;grid-row: 1;}

    .single .images > div{max-height: unset;}

    .m_profile_menu{display: block;}
    .m_profile_menu a{display: block;border-bottom: 1px solid rgba(0,0,0,0.1);border-radius: 0;padding-bottom: 10px;padding-top: 10px;margin-right: 0;text-align: center;}

    .av.large{margin: 0 auto 20px;}

    .gla-form label{display: block;text-align: left;margin-bottom: 30px;}
    .gla-form label input, .gla-form label select, .gla-form label textarea{display: block;width: 100% !important;border: 1px solid rgba(118, 118, 118, 0.6) !important;margin-top: 5px;}
    .gla-form label textarea{height: 155px}
    .gla-form label > span{display: block;}
    .gla-form input[type=checkbox]{width: 20px !important;display: inline-block !important;}

    .settings .gla-form label{width: 100% !important}
    
    .gla-form div.label{display: block;}

    .discussions_page{overflow: hidden;padding: 10px;height: calc(100vh - 110px);margin-top: 23px;}
    .messages{height: calc(100vh - 260px)}

    .boite-box{padding-top: 2px;padding-bottom: 20px;}

    .mask .btn, .mask .exit{font-size: 3em}
    .mask .btn{top: 85%}

    .single .images .btn2{display: none}

    form.credit-card{width: 100%}
    form .form-body{padding: 10px}
    form .month select, form .year select{width: 49%}

    footer .social{display: block;}
    footer .social a, footer .social .flex{display: inline-block}
    footer .social .flex{margin-top: 14px}
    footer .social .flex a{margin-top: 0}

    .prfl .rating{margin-left: 0}
    .rating {margin-left: 6px}

    .note{padding: 10px}

    .discussions{height: calc(100vh - 180px)}

    ._choix{width: 105px;margin-right: 5px}

    .m_flex{display: flex;}
    .m_f_wrap{flex-wrap: wrap;}

    .m_d_block{display: block;}

    .m_col_10{width: 100%;}
    .m_col_25{width: 25%;}
    .m_col_1{width: 10%;}
    .m_col_2{width: 20%;}
    .m_col_3{width: 30%;}
    .m_col_4{width: 40%;}
    .m_col_8{width: 80%;}
    .m_d_none{display: none;}
    .m_d_in_b{display: inline-block !important}
    .m_fff{background-color: #fff;}
    .m_o_0{visibility: hidden;}
    .m_p10{padding: 10px;}
    .m_ta_center{text-align: center;}
    .m_ta_left{text-align: left;}
    .m_mt_0{margin-top: 0px !important}
    .m_mt_10{margin-top: 10px;}
    .m_mt_20{margin-top: 20px;}
    .m_mt_30{margin-top: 30px;}
    .m_mt_40{margin-top: 40px;}
    .m_mb_10{margin-bottom: 10px;}
    .m_mb_20{margin-bottom: 20px;}
    .m_mb_40{margin-bottom: 40px;}
    .m_mb_0{margin-bottom: 0px;}
    .m_ml_10{margin-left: 10px;}
    .m_ml_15{margin-left: 15px;}
    .m_ml_0{margin-left: 0}
    .m_mr_0{margin-right: 0}
    .m_p20{padding: 20px}
    .m_p0{padding: 0 !important}
    .m_p10{padding: 10px !important}
    .shape1, .shape2, .shape3, .shape4{display: none;}
    .m_op_0{opacity: 0}
    .m_jc_between{justify-content: space-between}

    .w_h_60{width: 60px !important;height: 60px !important;}

    .m_col-4{width: 40%;}
    .m_col-7{width: 70%;}
    .m_col-8{width: 80%;}

    ul{padding: 0;}
    ul li{list-style-type: none}

    .cookie_box{bottom: 0;width: 100%;border-radius: 0;}

}